// Slick slider


$slick-next-character: "\E684" !default;
$slick-prev-character: "\E686" !default;

// Core

.slick-slider {
  position: relative;
  display: block;

  user-select: none;
  touch-action: pan-y;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;

  & > div {
    outline: none;

    & > div {
      outline: none;
    }
  }

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slider-right,
.slick-slider-left {
  .slick-arrow {
    display: none !important;
  }

  .slick-list.draggable {
    cursor: all-scroll;
  }
}

.slick-slider-left {
  .slick-dots {
    text-align: left;

    li {
      margin: 0 0 0 ($spacer / 1.5);
    }
  }
}

.slick-slider-left {
  .slick-dots {
    text-align: left;

    li {
      margin: 0 ($spacer / 1.5) 0 0;
    }
  }
}

// Theme

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 38px;
  width: 38px;
  cursor: pointer;
  color: $primary;
  top: 50%;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: $box-shadow-sm;
  transition: $transition-base;
  @include border-radius(50px);
  background: $white;
  z-index: 6;
  overflow: hidden;
  opacity: 0;
  font-family: 'Pe-icon-7-stroke';
  margin-top: -19px;

  &::before {
    font-size: 35px;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  &:hover, &:focus {
    outline: none;
    color: $white;
    background: $primary;
  }

  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-arrow {
  i {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 38px;
    line-height: 40px;
  }
}

.slick-prev {
  left: -38px;
  &:before {
    content: $slick-prev-character;
  }
}

.slick-next {
  right: -38px;
  &:before {
    content: $slick-next-character;
  }
}

.slick-slider {
  &:hover {

    .slick-next,
    .slick-prev {
      opacity: 1;
    }

    .slick-next {
      right: -3px;
    }

    .slick-prev {
      left: -3px;
    }
  }
}

/* Dots */

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 8px;

  li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 ($spacer / 3);
    cursor: pointer;
    transition: $transition-base;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 8px;
      width: 8px;
      padding: 0;
      background: $gray-600;
      cursor: pointer;
      position: relative;
      @include border-radius($border-radius);
      color: transparent;
      transition: $transition-base;
      outline: none !important;

    }

    &.slick-active {
      button {
        width: 26px;
        background: $primary;
      }
    }
  }
}

.slick-center {
  transform: scale(1.5);
}

.slick-slider {
  .slide-img-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: .4;
    z-index: 10;
  }

  .slider-content {
    position: relative;
    z-index: 15;
    text-align: center;
    margin: 0 ($spacer * 2);

    h3 {
      font-size: $h3-font-size;
      font-weight: normal;
      margin-bottom: $spacer;
    }

    p {
      font-size: $h6-font-size;
      opacity: .7;
    }
  }
}

// slider-light

.slider-light {
  .slick-dots {
    position: absolute;
    bottom: 10px;

    li button {
      background: rgba(255, 255, 255, 0.25);
    }
  }

  .slick-prev,
  .slick-next {
    background: transparent;
    color: $white;
    box-shadow: 0 0 0 0 transparent;

    &:hover {
      background: rgba(255, 255, 255, .15);
    }
  }

  .slick-next {
    right: 15px;
  }

  .slick-prev {
    left: 15px;
  }

  .slider-content {
    color: $white;
  }
}

